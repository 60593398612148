<template>
    <nav class="navbar navbar-expand-lg navbar-light w-100"
        :class="{ 'bg-white': theme === 'light', 'bg-dark': theme === 'dark' }">
        <div class="container-fluid">
            <!-- <a class="navbar-brand" href="javascript:;">{{ platformName }}</a> -->
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
                aria-controls="navbarCollapse" aria-expanded="false">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="navbar-collapse collapse" id="navbarCollapse">
                <div class="logo-box">
                    <router-link :to="{ name: 'home' }">
                        <img class="logo-top-bar" src="/svg/INNO4CFIs-logo.svg">
                    </router-link>
                </div>
                <ul class="navbar-nav d-flex justify-content-start">
                    <li class="nav-item">
                        <router-link :to="{ name: 'home' }" class="nav-link" :class="{ active: pageActive === 'home' }"
                            aria-current="page">{{ $t("Home") }}</router-link>
                        <div class="sub-active" :class="{ active: pageActive === 'home' }"></div>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{ name: 'projects' }" class="nav-link"
                            :class="{ active: pageActive === 'projects' }" href="#">{{ $t("Projects") }}</router-link>
                        <div class="sub-active" :class="{ active: pageActive === 'projects' }"></div>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{ name: 'guidelines' }" class="nav-link"
                            :class="{ active: pageActive === 'guidelines' }" href="#">{{ $t("Guidelines")
                            }}</router-link>
                        <div class="sub-active" :class="{ active: pageActive === 'guidelines' }"></div>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{ name: 'credit-registry' }" class="nav-link"
                            :class="{ active: pageActive === 'credit-registry' }" href="#">{{ $t("Credit Registry")
                            }}</router-link>
                        <div class="sub-active" :class="{ active: pageActive === 'credit-registry' }"></div>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{ name: 'satellite' }" class="nav-link"
                            :class="{ active: pageActive === 'satellite' }" href="#">{{ $t("Satellite")
                            }}</router-link>
                        <div class="sub-active" :class="{ active: pageActive === 'satellite' }"></div>
                    </li>
                </ul>
                <!-- <form class="d-flex">
                    <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
                    <button class="btn btn-outline-success" type="submit">{{ $t("Search") }}</button>
                </form> -->

                <div v-if="loggedIn" class="top-bar-login-container">
                    <button type="button" class="btn language-btn" :title="$t('Select your language')"
                        @click="selectLanguage">
                        <i class="fa-solid fa-globe"></i>
                    </button>
                    <div class="dropdown">
                        <!-- <span class="top-bar-hello-message">{{  renderName(profileName) }}</span> -->
                        <button type="button" class="top-bar-button-img" :title="$t('User settings')"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            <!-- <img v-if="profileImage" class="btn-image" :src="profileImage" />
                            <img v-else class="btn-image" src="@/assets/user.png" /> -->
                            <i class="fa-solid fa-user"></i>
                        </button>
                        <div class="dropdown-menu"
                            :class="{ 'dropdown-menu-end': windowInnerWidth > 992, 'bg-white': theme === 'light', 'bg-dark': theme === 'dark' }">
                            <AccountSettingsDropdown @openModal="openModal"></AccountSettingsDropdown>
                        </div>
                    </div>
                </div>

                <div v-if="!loggedIn" class="top-bar-login-container">
                    <!-- 
                    <button type="button" class="btn" :title="$t('Change theme')" @click="invertTheme">
                        <i v-if="theme === 'dark'" class="fas fa-sun"></i>
                        <i v-else class="fas fa-moon"></i>
                    </button> -->
                    <button type="button" class="btn language-btn" :title="$t('Select your language')"
                        @click="selectLanguage">
                        <i class="fa-solid fa-globe"></i>
                    </button>
                    <button type="button" @click="login" class="btn btn-primary">
                        <i class="fas fa-sign-in"></i> {{ $t("Login") }}
                    </button>
                </div>
            </div>
        </div>
    </nav>
</template>

<script lang="ts">
import { ColorThemeName, getTheme, setTheme } from "@/control/app-preferences";
import { AuthController } from "@/control/auth";
import { defineComponent } from "vue";
import AccountSettingsDropdown from "@/components/dropdowns/account/AccountSettingsDropdown.vue";

export default defineComponent({
    components: {
        AccountSettingsDropdown,
    },
    name: "TopBar",
    emits: ["toggle-menu", "openModal"],
    setup: function () {
        return {
            resizeObserver: null as ResizeObserver,
        };
    },
    data: function () {
        return {
            platformName: import.meta.env.VITE__PLATFORM_NAME || "Platform",
            loggedIn: AuthController.isAuthenticated(),
            profileImage: AuthController.ProfileImage,
            theme: getTheme(),
            profileName: AuthController.ProfileName || AuthController.Username || "",
            windowInnerWidth: window.innerWidth,

            pageActive: "",

        };
    },
    methods: {
        openModal: function (d: string) {
            this.$emit("openModal", d);
        },

        openUserSettings: function () {
            this.$emit("openModal", "account-settings");
        },

        selectLanguage: function () {
            this.$emit("openModal", "change-language-modal");
        },

        invertTheme: function () {
            setTheme(this.theme === "dark" ? "light" : "dark");
        },

        login: function () {
            this.$requireLogin();
        },

        clickMenu: function () {
            this.$emit("toggle-menu");
        },

        onAuthChanged: function () {
            this.loggedIn = AuthController.isAuthenticated();
            this.profileName = AuthController.ProfileName || AuthController.Username || "";
            this.profileImage = AuthController.ProfileImage;
        },

        onThemeChanged: function (t: ColorThemeName) {
            this.theme = t;
        },

        renderName: function (name: string): string {
            return ((name + "").split(" ")[0] + "").split(",")[0] || "???";
        },

        onPageResize: function () {
            this.windowInnerWidth = window.innerWidth;
        },

    },
    mounted: function () {
        this.$listenOnAppEvent("auth-status-changed", this.onAuthChanged.bind(this));
        this.$listenOnAppEvent("theme-changed", this.onThemeChanged.bind(this));

        if (window.ResizeObserver) {
            this.resizeObserver = new ResizeObserver(this.onPageResize.bind(this));
            this.resizeObserver.observe(this.$el);
        }

    },
    beforeUnmount: function () { },

    watch: {
        $route(to) {
            const pageid = to.name + "";
            if (this.pageActive !== pageid) {
                this.pageActive = pageid;
            }
        }
    },
});
</script>

<style scoped>
@import "@/style/layout/topBar.css";
</style>
